import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const WalletFilledV2Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 18 18" {...props}>
      <path
        d="M4.10386 15.4522C3.67286 15.4522 3.30573 15.3006 3.00248 14.9974C2.69923 14.6941 2.54761 14.327 2.54761 13.896V4.10361C2.54761 3.67261 2.69923 3.30549 3.00248 3.00224C3.30573 2.69899 3.67286 2.54736 4.10386 2.54736H13.8962C14.3272 2.54736 14.6944 2.69899 14.9976 3.00224C15.3009 3.30549 15.4525 3.67261 15.4525 4.10361H9.45905C8.72692 4.10361 8.10136 4.36311 7.58236 4.88211C7.06336 5.40111 6.80386 6.02668 6.80386 6.7588V11.2498C6.80386 11.9819 7.06336 12.606 7.58236 13.122C8.10136 13.638 8.72692 13.896 9.45905 13.896H15.4525C15.4525 14.3307 15.3009 14.6988 14.9976 15.0002C14.6944 15.3016 14.3272 15.4522 13.8962 15.4522H4.10386ZM9.45905 12.5998C9.0908 12.5998 8.77223 12.4669 8.50336 12.201C8.23448 11.9351 8.10005 11.6181 8.10005 11.2498V6.7588C8.10005 6.39055 8.23448 6.07199 8.50336 5.80311C8.77223 5.53424 9.0908 5.3998 9.45905 5.3998H14.9935C15.3617 5.3998 15.6803 5.53424 15.9492 5.80311C16.218 6.07199 16.3525 6.39055 16.3525 6.7588V11.2498C16.3525 11.6181 16.218 11.9351 15.9492 12.201C15.6803 12.4669 15.3617 12.5998 14.9935 12.5998H9.45905ZM12.15 10.1248C12.4625 10.1248 12.7282 10.0154 12.9469 9.79668C13.1657 9.57793 13.275 9.3123 13.275 8.9998C13.275 8.6873 13.1657 8.42168 12.9469 8.20293C12.7282 7.98418 12.4625 7.8748 12.15 7.8748C11.8375 7.8748 11.5719 7.98418 11.3532 8.20293C11.1344 8.42168 11.025 8.6873 11.025 8.9998C11.025 9.3123 11.1344 9.57793 11.3532 9.79668C11.5719 10.0154 11.8375 10.1248 12.15 10.1248Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default WalletFilledV2Icon;